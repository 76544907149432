<wdx-avatar-container
    *ngIf="avatar"
    [popupEnabled]="popupEnabled"
    (click)="avatarClicked()"
    wdxStopPropagation
>
    <wdx-avatar wdxAvatar [avatar]="avatar" [size]="'sm'"></wdx-avatar>
    <div wdxAvatarPopupContent *ngIf="popupEnabled">
        <wdx-avatar-popup-content
            wdxAvatarPopupContent
            *ngIf="popupContent$ | ngrxPush as content; else loadingTmpl"
            [avatar]="avatar"
            [content]="content"
        ></wdx-avatar-popup-content>
    </div>
</wdx-avatar-container>

<span *ngIf="!url && label" class="ms-2" [attr.data-cy]="'avatar-cell-label'">{{
    label
}}</span>
<a
    *ngIf="url"
    class="ms-2"
    [routerLink]="[url]"
    [target]="newTab ? '_blank' : ''"
    [attr.data-cy]="'avatar-cell-link'"
    >{{ label }}</a
>

<ng-template #loadingTmpl>
    <div class="w-100 d-flex px-5 py-2 mx-5 my-2">
        <wdx-spinner></wdx-spinner>
    </div>
</ng-template>
