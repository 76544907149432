import { Component, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import {
    Avatar,
    WdxAvatarModule,
    AvatarDetailPopup,
} from '@wdx/shared/components/wdx-avatar';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { ICellRendererParams, ColDef } from '@ag-grid-community/core';
import { Observable } from 'rxjs';
import { PushModule } from '@ngrx/component';
import { stringComparator } from '../../utils';
import { WdxStopPropagationDirective } from '@wdx/shared/components/wdx-stop-propagation';

export interface AvatarCellRendererValue {
    avatar: Avatar;
    label?: string;
    url?: string;
    newTab?: boolean;
}

export interface AvatarCellRendererParams {
    popupContent$(avatar: Avatar): Observable<AvatarDetailPopup>;
}

export const avatarCellRendererComparator = (
    valueA: AvatarCellRendererValue,
    valueB: AvatarCellRendererValue
) => stringComparator(valueA?.avatar?.text || '', valueB?.avatar?.text || '');

export const AVATAR_CELL_RENDERER_COL_DEFS: ColDef = {
    width: 80,
    maxWidth: 95,
};

@Component({
    selector: 'wdx-ag-grid-avatar-cell-renderer',
    standalone: true,
    imports: [
        CommonModule,
        WdxAvatarModule,
        PushModule,
        WdxSpinnerModule,
        WdxStopPropagationDirective,
        RouterModule,
    ],
    templateUrl: './avatar-cell-renderer.component.html',
})
export class AvatarCellRendererComponent implements ICellRendererAngularComp {
    @HostBinding('class') class = 'd-flex align-items-center';

    public avatar?: Avatar;
    public label?: string;
    public url?: string;
    public newTab?: boolean;

    public popupEnabled = false;
    public popupContent$?: Observable<AvatarDetailPopup>;
    private _popupContent$?: (avatar: Avatar) => Observable<AvatarDetailPopup>;

    public agInit(
        params: ICellRendererParams<any, AvatarCellRendererValue>
    ): void {
        this.avatar = params.value?.avatar;
        this.label = params.value?.label;
        this.url = params.value?.url;
        this.newTab = params.value?.newTab;

        if (params.colDef?.cellRendererParams?.popupContent$) {
            this.popupEnabled = true;
            this._popupContent$ =
                params.colDef?.cellRendererParams?.popupContent$;
        }
    }

    public refresh(
        params: ICellRendererParams<any, AvatarCellRendererValue>
    ): boolean {
        this.agInit(params);
        return true;
    }

    public avatarClicked() {
        if (this._popupContent$ && this.avatar) {
            this.popupContent$ = this._popupContent$(this.avatar);
        }
    }
}
